/*
 * @Author: your name
 * @Date: 2021-11-08 11:29:24
 * @LastEditTime: 2021-11-16 14:03:17
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \crm-product（西凤产品web）\found\views\sfa\views\reports\package_amount_fee\index.js
 */
import TablePage from '../../../../../components/table_page';
import Form from './form/index.js';

export default {
  name: 'table-component',
  extends: TablePage,
  data() {
    return {};
  },
  components: {
    Form,
  },
  methods: {
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'view') {
        this.modalConfig.title = '详情';
        this.formName = 'Form';
        this.formConfig = {
          name: 'CTMT',
          code,
          row,
        };
        this.openFull();
      }
    },
  },
  created() {
    this.getConfigList('traditional_media_fee');
  },

};
